/* eslint-disable no-undef */
import { useContext, useState } from 'react';
import { Button, Tooltip, Menu, Dropdown, Space, Input } from 'antd';
import {
  CheckOutlined,
  DownOutlined,
  UpOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import ZoomMediaContext from '../../../context/media-context';
import classNames from 'classnames';
import { MediaDevice } from '../video-types';
import { getAntdDropdownMenu, getAntdItem, MenuItem } from './video-footer-utils';
interface CameraButtonProps {
  isStartedVideo: boolean;
  isMirrored?: boolean;
  isBlur?: boolean;
  isBackGround?: boolean;
  isPreview?: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (deviceId: string) => void;
  onMirrorVideo?: () => void;
  onVideoStatistic?: () => void;
  onBlurBackground?: () => void;
  onUpdateBackground?: (image: string) => void;
  onSelectVideoPlayback?: (url: string) => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
  activePlaybackUrl?: string;
}
const videoPlaybacks = [
  //   { title: 'Giới thiệu Vitus', url: 'https://www.youtube.com/watch?v=nqdmcdwgLvk' },
  { title: 'ZOOM ZWA', url: 'https://source.zoom.us/brand/mp4/Using%20the%20Zoom%20PWA.mp4' },
  { title: 'ZOOM Cares', url: 'https://source.zoom.us/brand/mp4/Zoom%20Cares%20Nonprofit%20Impact.mp4' },
  {
    title: 'ZOOM One',
    url: 'https://source.zoom.us/brand/mp4/Zoom%20One%20-%20Team%20Chat%2C%20Phone%2C%20Email%2C%20and%20more.mp4'
  }
];
const CameraButton = (props: CameraButtonProps) => {
  const {
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    isMirrored,
    isBlur,
    isBackGround,
    isPreview,
    activePlaybackUrl,
    onCameraClick,
    onSwitchCamera,
    onMirrorVideo,
    onVideoStatistic,
    onBlurBackground,
    onUpdateBackground,
    onSelectVideoPlayback
  } = props;
  const { mediaStream } = useContext(ZoomMediaContext);

  const [background, setBackground] = useState('');
  const [backgroundOnline, setBackgroundOnline] = useState('');
  const menuItemBackground = [
    getAntdItem('Logo Vitus', 'logo-vitus', background === 'logo-vitus' && <CheckOutlined />),
    getAntdItem('Quốc kỳ Việt Nam', 'vietnam', background === 'vietnam' && <CheckOutlined />),
    getAntdItem('Nền trắng', 'white', background === 'white' && <CheckOutlined />),
    getAntdItem('Mặt trăng', 'moon', background === 'moon' && <CheckOutlined />),
    getAntdItem(
      <Input
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setBackgroundOnline(e.target.value)}
        onPressEnter={async (e) => {
          e.stopPropagation();
          onUpdateBackground?.(backgroundOnline);
        //   try {
        //     const response = await fetch(backgroundOnline);
        //     const blob = await response.blob();
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //       const base64data = reader.result;
        //       if (typeof base64data === 'string') {
        //         onUpdateBackground?.(base64data);
        //       } else {
        //         console.error('Error: base64data is not a string');
        //       }
        //     };
        //     reader.readAsDataURL(blob);
        //   } catch (error) {
        //     console.error('Error fetching the background image:', error);
        //   }
        }}
        placeholder="Nhập link background"
      />,
      'online',
      background === 'online' && <CheckOutlined />
    )
  ] as MenuItem[];
  const onMenuItemBackgroundClick = (payload: { key: any }) => {
    let imagePath = '';
    if (payload.key !== '') {
      imagePath = `/images/backgrounds/${payload.key}.png`;
    } else {
      imagePath = 'blur';
    }
    setBackground(payload.key);
    onUpdateBackground?.(imagePath);
  };

  const onMenuItemClick = (payload: { key: any }) => {
    if (payload.key === 'mirror') {
      onMirrorVideo?.();
    } else if (payload.key === 'statistic') {
      onVideoStatistic?.();
    } else if (payload.key === 'blur') {
      setBackground('');
      onBlurBackground?.();
    } else if (/^https:\/\//.test(payload.key)) {
      onSelectVideoPlayback?.(payload.key);
    } else if (payload.key === 'choice-background') {
      console.log('choice-background');
    } else {
      onSwitchCamera(payload.key);
    }
  };
  const menuItems =
    cameraList &&
    cameraList.length > 0 &&
    ([
      getAntdItem(
        'Select a Camera',
        'camera',
        undefined,
        cameraList.map((item) =>
          getAntdItem(item.label, item.deviceId, item.deviceId === activeCamera && <CheckOutlined />)
        ),
        'group'
      ),
      !isPreview &&
        getAntdItem(
          'Select a Video Playback',
          'playback',
          undefined,
          videoPlaybacks.map((item) =>
            getAntdItem(item.title, item.url, item.url === activePlaybackUrl && <CheckOutlined />)
          ),
          'group'
        ),
      getAntdItem('', 'd1', undefined, undefined, 'divider'),
      !isPreview && getAntdItem('Mirror My Video', 'mirror', isMirrored && <CheckOutlined />),
      mediaStream?.isSupportVirtualBackground()
        ? getAntdItem('Làm mờ Background', 'blur', isBlur && <CheckOutlined />)
        : getAntdItem('Mask My Background', 'blur'),
      getAntdItem(
        <Dropdown
          className=""
          menu={getAntdDropdownMenu(menuItemBackground, onMenuItemBackgroundClick)}
          // onClick={onCameraClick}
          trigger={['hover']}
          placement="topRight"
        >
          <Space>
            Chọn nền ảo
            {/* <UpOutlined /> */}
          </Space>
        </Dropdown>,
        'choice-background',
        isBackGround && <CheckOutlined />
      ),
      !isPreview && getAntdItem('', 'd2', undefined, undefined, 'divider'),
      !isPreview && getAntdItem('Video Statistic', 'statistic')
    ].filter(Boolean) as MenuItem[]);

  return (
    <div className={classNames('camera-footer', className)}>
      {isStartedVideo && menuItems ? (
        <Dropdown.Button
          className="vc-dropdown-button"
          size="large"
          menu={getAntdDropdownMenu(menuItems, onMenuItemClick)}
          onClick={onCameraClick}
          trigger={['click']}
          type="ghost"
          icon={<UpOutlined />}
          placement="topRight"
        >
          <VideoCameraOutlined />
        </Dropdown.Button>
      ) : (
        <Tooltip title={`${isStartedVideo ? 'stop camera' : 'start camera'}`}>
          <Button
            className={classNames('vc-button', className)}
            icon={isStartedVideo ? <VideoCameraOutlined /> : <VideoCameraAddOutlined />}
            ghost={true}
            shape="circle"
            size="large"
            onClick={onCameraClick}
          />
        </Tooltip>
      )}
    </div>
  );
};
export default CameraButton;
