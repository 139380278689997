/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable max-nested-callbacks */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import { useState, useCallback, useContext, useEffect, MutableRefObject, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { message, Modal, Form, Select, Checkbox, Tooltip, Button, Input, Drawer, Radio } from 'antd';
import { SoundOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined, EditOutlined, WechatOutlined, TeamOutlined, UserOutlined, AudioOutlined, AudioMutedOutlined } from '@ant-design/icons';
import ZoomContext from '../../../context/zoom-context';
import CameraButton from './camera';
import MicrophoneButton from './microphone';
import { ScreenShareButton } from './screen-share';
import AudioVideoStatisticModal from './audio-video-statistic';
import ZoomMediaContext from '../../../context/media-context';
import { useUnmount, useMount, useOrientation } from '../../../hooks';
import { MediaDevice, DrawerOb } from '../video-types';
import Chat from '../../../feature/chat/chat';
import './video-footer.scss';
import { useChat } from '../../../feature/chat/hooks/useChat';
import { isAndroidOrIOSBrowser, isIOSMobile } from '../../../utils/platform';
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from '../video-constants';
import { getRecordingButtons, RecordButtonProps, RecordingButton } from './recording';
import {
    DialoutState,
    RecordingStatus,
    MutedSource,
    AudioChangeAction,
    DialOutOption,
    VideoCapturingState,
    SharePrivilege,
    MobileVideoFacingMode,
    LiveStreamStatus,
    ShareStatus,
    VideoQuality,
    VideoPlayer
} from '@zoom/videosdk';
import { LiveTranscriptionButton } from './live-transcription';
import { LeaveButton } from './leave';
import { TranscriptionSubtitle } from './transcription-subtitle';
import IsoRecordingModal from './recording-ask-modal';
import { LiveStreamButton, LiveStreamModal } from './live-stream';
import { IconFont } from '../../../component/icon-font';
import { VideoMaskModel } from './video-mask-modal';
import { useHistory } from 'react-router-dom';
import { Participant } from '../../../index-types';
import { useNetworkQuality } from './../hooks/useNetworkQuality';
import { useActiveVideo } from './../hooks/useAvtiveVideo';

import AvatarActionContext from './../context/avatar-context';
import { useAvatarAction } from '../hooks/useAvatarAction';
import { useVideoAspect } from '../hooks/useVideoAspectRatio';
import Avatar from '../components/avatar';
import { useAudioLevel } from '../hooks/useAudioLevel';
import { AudoiAnimationIcon } from '../../../component/audio-animation-icon';
import ParticipantItem from './participant-item';

function maxVideoCellWidth(orientation: string, totalParticipants: number, spotlighted?: boolean[]) {
    return orientation === 'portrait' ? 'none' : `calc(100vw/${Math.min(totalParticipants, spotlighted ? 2 : 4)})`;
}
interface VideoFooterProps {
    className?: string;
    selfShareCanvas?: HTMLCanvasElement | HTMLVideoElement | null;
    sharing?: boolean;
    sortedParticipants: Participant[];
    drawers: DrawerOb;
    showDrawer: (drawerName: keyof DrawerOb) => void;
    closeDrawer: (drawerName: keyof DrawerOb) => void;
}

const isAudioEnable = typeof AudioWorklet === 'function';
const VideoFooter = (props: VideoFooterProps) => {
    const { className, selfShareCanvas, sharing, sortedParticipants } = props;
    const zmClient = useContext(ZoomContext);
    const { mediaStream } = useContext(ZoomMediaContext);
    const liveTranscriptionClient = zmClient.getLiveTranscriptionClient();
    const liveStreamClient = zmClient.getLiveStreamClient();
    const recordingClient = zmClient.getRecordingClient();
    const [isStartedAudio, setIsStartedAudio] = useState(
        zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== ''
    );
    const [isStartedVideo, setIsStartedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn);
    const [audio, setAudio] = useState(zmClient.getCurrentUserInfo()?.audio);
    const [isSupportPhone, setIsSupportPhone] = useState(false);
    const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
    const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
    const [isStartedLiveTranscription, setIsStartedLiveTranscription] = useState(false);
    const [isDisableCaptions, setIsDisableCaptions] = useState(false);
    const [isMirrored, setIsMirrored] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [isBackGround, setIsBackGround] = useState(false);
    const [isMuted, setIsMuted] = useState(!!zmClient.getCurrentUserInfo()?.muted);
    const [activeMicrophone, setActiveMicrophone] = useState(mediaStream?.getActiveMicrophone());
    const [activeSpeaker, setActiveSpeaker] = useState(mediaStream?.getActiveSpeaker());
    const [activeCamera, setActiveCamera] = useState(mediaStream?.getActiveCamera());
    const [micList, setMicList] = useState<MediaDevice[]>(mediaStream?.getMicList() ?? []);
    const [speakerList, setSpeakerList] = useState<MediaDevice[]>(mediaStream?.getSpeakerList() ?? []);
    const [cameraList, setCameraList] = useState<MediaDevice[]>(mediaStream?.getCameraList() ?? []);
    const [statisticVisible, setStatisticVisible] = useState(false);
    const [selecetedStatisticTab, setSelectedStatisticTab] = useState('audio');
    const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
    const [sharePrivilege, setSharePrivileg] = useState(SharePrivilege.Unlocked);
    const [caption, setCaption] = useState({ text: '', isOver: false });
    const [activePlaybackUrl, setActivePlaybackUrl] = useState('');
    const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState<'' | RecordingStatus>(
        recordingClient?.getCloudRecordingStatus() || ''
    );
    const [recordingIsoStatus, setRecordingIsoStatus] = useState<'' | RecordingStatus>('');
    const [liveStreamVisible, setLiveStreamVisible] = useState(false);
    const [liveStreamStatus, setLiveStreamStatus] = useState(liveStreamClient?.getLiveStreamStatus());
    // Video Mask
    const [videoMaskVisible, setVideoMaskVisible] = useState(false);

    const [isSecondaryAudioStarted, setIsSecondaryAudioStarted] = useState(false);
    const [secondaryMicForm] = Form.useForm();

    // tuyen code start:
    const chatClient = zmClient.getChatClient();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpenChat, setIsOpenChat] = useState(false);
    const networkQuality = useNetworkQuality(zmClient);
    const activeVideo = useActiveVideo(zmClient);
    const iconRaiseHand = <img src="/images/raise-hand.png" alt="custom icon" style={{ width: 24, height: 24, marginRight: 10 }} />;
    const [isOpenAllUserView, setIsOpenAllUserView] = useState(false);
    const [isAllMute, setIsAllMute] = useState(false);
    const [userName, setUserName] = useState('');
    const [displayName, setDisplayName] = useState(zmClient.getCurrentUserInfo()?.displayName);
    const history = useHistory();
    const {
        records,
        receivers,
        privilege,
        chatUser,
        isHostOrManager,
        setChatUserId,
        sendMessage,
        sendFile,
        resendFile,
        downloadFile
    } = useChat(zmClient, chatClient);
    const [displayedMessages, setDisplayedMessages] = useState(new Set());

    const { drawers, showDrawer, closeDrawer } = props;

    const showModal = () => {
        setIsModalOpen(true);
    };

    // lấy tên user để hiển thị
    const getUserName = useCallback(() => {
        try {
            let object = JSON.parse(displayName);
            if (object && object.userName !== null && object.userName !== undefined) {
                return object.userName;
            }
        } catch (error) {
            return displayName;
        }
    }, [displayName]);

    // thay đổi tên user
    const handleOk = () => {
        if (userName.length > 50) {
            message.error('Tên người dùng không được vượt quá 100 ký tự!');
            return;
        }
        try {
            let object = JSON.parse(displayName);
            object.userName = userName;
            zmClient.changeName(JSON.stringify(object), zmClient.getCurrentUserInfo().userId);
            setDisplayName(JSON.stringify(object));
        } catch (error) {
            zmClient.changeName(userName, zmClient.getCurrentUserInfo().userId);
            setDisplayName(userName);
        }

        message.success('Bạn đã đổi tên thành công');
        setIsModalOpen(false);
        setUserName('');
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setUserName('');
    };
    // end

    const onCameraClick = useCallback(async () => {
        if (isStartedVideo) {
            await mediaStream?.stopVideo();
            setIsStartedVideo(false);
        } else {
            const startVideoOptions = {
                hd: true,
                fullHd: true,
                ptz: mediaStream?.isBrowserSupportPTZ(),
                originalRatio: true
            };
            if (mediaStream?.isSupportVirtualBackground() && isBlur) {
                Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
            }
            await mediaStream?.startVideo(startVideoOptions)
                .then(() => {

                })
                .catch(() => {
                    message.error("Có lỗi xảy ra. Vui lòng kiểm tra lại thiết bị của bạn và thử lại");
                    return
                });

            if (!mediaStream?.isSupportMultipleVideos()) {
                const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
                mediaStream?.renderVideo(
                    canvasElement,
                    zmClient.getSessionInfo().userId,
                    canvasElement.width,
                    canvasElement.height,
                    0,
                    0,
                    3
                );
            }
            // const temporaryException = isIOSMobile() && window.crossOriginIsolated; // add ios mobile exception for test backward compatible.
            // if (mediaStream?.isRenderSelfViewWithVideoElement() && !temporaryException) {
            //   const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;
            //   if (videoElement) {
            //     await mediaStream?.startVideo({ videoElement });
            //   }
            // } else {
            //   const startVideoOptions = { hd: true, fullHd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
            //   if (mediaStream?.isSupportVirtualBackground() && isBlur) {
            //     Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
            //   }
            //   await mediaStream?.startVideo(startVideoOptions);
            //   if (!mediaStream?.isSupportMultipleVideos()) {
            //     const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
            //     mediaStream?.renderVideo(
            //       canvasElement,
            //       zmClient.getSessionInfo().userId,
            //       canvasElement.width,
            //       canvasElement.height,
            //       0,
            //       0,
            //       3
            //     );
            //   }
            // }

            setIsStartedVideo(true);
        }
    }, [mediaStream, isStartedVideo, zmClient, isBlur]);
    const onMicrophoneClick = useCallback(async () => {
        if (isStartedAudio) {
            if (isMuted) {
                await mediaStream?.unmuteAudio();
            } else {
                await mediaStream?.muteAudio();
            }
        } else {
            try {
                await mediaStream?.startAudio({ highBitrate: true });
            } catch (e: any) {
                if (e.type === 'INSUFFICIENT_PRIVILEGES' && e.reason === 'USER_FORBIDDEN_MICROPHONE') {
                    setIsMicrophoneForbidden(true);
                }
                console.warn(e);
            }
            // setIsStartedAudio(true);
        }
    }, [mediaStream, isStartedAudio, isMuted]);
    const onMicrophoneMenuClick = async (key: string) => {
        if (mediaStream) {
            const [type, deviceId] = key.split('|');
            if (type === 'microphone') {
                if (deviceId !== activeMicrophone) {
                    await mediaStream.switchMicrophone(deviceId);
                    setActiveMicrophone(mediaStream.getActiveMicrophone());
                }
            } else if (type === 'speaker') {
                if (deviceId !== activeSpeaker) {
                    await mediaStream.switchSpeaker(deviceId);
                    setActiveSpeaker(mediaStream.getActiveSpeaker());
                }
            } else if (type === 'leave audio') {
                if (audio === 'computer') {
                    await mediaStream.stopAudio();
                } else if (audio === 'phone') {
                    await mediaStream.hangup();
                    setPhoneCallStatus(undefined);
                }
            } else if (type === 'statistic') {
                setSelectedStatisticTab('audio');
                setStatisticVisible(true);
            } else if (type === 'secondary audio') {
                if (isSecondaryAudioStarted) {
                    await mediaStream.stopSecondaryAudio();
                    setIsSecondaryAudioStarted(false);
                } else {
                    Modal.confirm({
                        title: 'Start secondary audio',
                        content: (
                            <Form form={secondaryMicForm}>
                                <Form.Item label="Microphone" name="mic" required>
                                    <Select
                                        options={mediaStream.getMicList().map((item) => ({
                                            value: item.deviceId,
                                            label: item.label,
                                            disabled: item.deviceId === mediaStream.getActiveMicrophone()
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item label="Contraintes" name="constraints">
                                    <Checkbox.Group
                                        options={[
                                            { label: 'AGC', value: 'autoGainControl' },
                                            {
                                                label: 'ANC',
                                                value: 'noiseSuppression'
                                            },
                                            {
                                                label: 'AEC',
                                                value: 'echoCancellation'
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            </Form>
                        ),
                        onOk: async () => {
                            try {
                                const data = await secondaryMicForm.validateFields();
                                const { mic, constraints } = data;
                                const option = {};
                                if (constraints) {
                                    constraints.forEach((key: string) => {
                                        Object.assign(option, { [`${key}`]: true });
                                    });
                                }
                                await mediaStream.startSecondaryAudio(mic, option);
                                setIsSecondaryAudioStarted(true);
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    });
                }
            }
        }
    };
    const onSwitchCamera = async (key: string) => {
        if (mediaStream) {
            if (activeCamera !== key) {
                await mediaStream.switchCamera(key);
                setActiveCamera(mediaStream.getActiveCamera());
                setActivePlaybackUrl('');
            }
        }
    };
    const onMirrorVideo = async () => {
        await mediaStream?.mirrorVideo(!isMirrored);
        setIsMirrored(!isMirrored);
    };
    const onBlurBackground = async () => {
        const isSupportVirtualBackground = mediaStream?.isSupportVirtualBackground();
        
        if (isSupportVirtualBackground) {
            if (isBlur) {
                await mediaStream?.updateVirtualBackgroundImage(undefined);
            } else {
                await mediaStream?.updateVirtualBackgroundImage('blur');
            }
        } else {
            setVideoMaskVisible(true);
        }
        setIsBackGround(false);
        setIsBlur(!isBlur);
    };

    const onUpdateBackground = async (image: string) =>{
        const isSupportVirtualBackground = mediaStream?.isSupportVirtualBackground();
        if (isSupportVirtualBackground) {
            if(image === 'blur'){
                await mediaStream?.updateVirtualBackgroundImage('blur')
                .then(() => {
                    message.success("Thay đổi nền thành công");
                })
                .catch((error) => {
                    message.error("Có lỗi xảy ra " + error);
                });
                setIsBlur(true);
                setIsBackGround(false);
            }else if(image === 'off'){
                await mediaStream?.updateVirtualBackgroundImage(undefined)
                .then(() => {
                    message.success("Thay đổi nền thành công");
                })
                .catch((error) => {
                    message.error("Có lỗi xảy ra " + error);
                });
                setIsBlur(false);
                setIsBackGround(false);
            }else{
                console.log("image: ", image);
                await mediaStream?.updateVirtualBackgroundImage(image)
                .then(() => {
                    message.success("Thay đổi nền thành công");
                })
                .catch((error) => {
                    message.error("Có lỗi xảy ra " + error);
                });
                setIsBlur(false);
                setIsBackGround(true);
            }
        } else {
            setVideoMaskVisible(true);
        }
    }
    const onPhoneCall = async (code: string, phoneNumber: string, name: string, option: DialOutOption) => {
        await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
    };
    const onPhoneCallCancel = async (code: string, phoneNumber: string, option: { callMe: boolean }) => {
        if ([DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(phoneCallStatus as any)) {
            await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true);
                }, 3000);
            });
        }
        return Promise.resolve();
    };
    const onHostAudioMuted = useCallback(
        (payload: any) => {
            const { action, source, type } = payload;
            if (action === AudioChangeAction.Join) {
                setIsStartedAudio(true);
                setAudio(type);
                setTimeout(() => {
                    setIsMuted(!!zmClient.getCurrentUserInfo()?.muted);
                }, 1000);
            } else if (action === AudioChangeAction.Leave) {
                setIsStartedAudio(false);
            } else if (action === AudioChangeAction.Muted) {
                setIsMuted(true);
                if (source === MutedSource.PassiveByMuteOne) {
                    message.info('Host muted you');
                }
            } else if (action === AudioChangeAction.Unmuted) {
                setIsMuted(false);
                if (source === 'passive') {
                    message.info('Host unmuted you');
                }
            }
        },
        [zmClient]
    );
    const onScreenShareClick = useCallback(async () => {
        if (mediaStream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
            await mediaStream?.startShareScreen(selfShareCanvas, { requestReadReceipt: true })
                .then(() => {
                    console.log('Chia sẻ màn hình');
                })
                .catch((error) => {
                    console.log('lỗi chia sẻ màn hình: ', error);
                });
        }
    }, [mediaStream, selfShareCanvas]);

    const onLiveTranscriptionClick = useCallback(async () => {
        if (isDisableCaptions) {
            message.info('Captions has been disable by host.');
        } else if (isStartedLiveTranscription) {
            message.info('Live transcription has started.');
        } else if (!isStartedLiveTranscription) {
            await liveTranscriptionClient?.startLiveTranscription();
            setIsStartedLiveTranscription(true);
        }
    }, [isStartedLiveTranscription, isDisableCaptions, liveTranscriptionClient]);

    const onDisableCaptions = useCallback(
        async (disable: boolean) => {
            if (disable && !isDisableCaptions) {
                await liveTranscriptionClient?.disableCaptions(disable);
                setIsStartedLiveTranscription(false);
                setIsDisableCaptions(true);
            } else if (!disable && isDisableCaptions) {
                await liveTranscriptionClient?.disableCaptions(disable);
                setIsDisableCaptions(false);
            }
        },
        [isDisableCaptions, liveTranscriptionClient]
    );

    const onLeaveClick = useCallback(async () => {
        await zmClient.leave()
        .then(() => {
            console.log('leave');
        })
        .catch((error) => {
            console.error('error: ', error);
        });
        history.push('/');
    }, [zmClient, history]);

    const onEndClick = useCallback(async () => {
        await zmClient.leave(true);
    }, [zmClient]);

    const onPassivelyStopShare = useCallback(({ reason }: any) => {
        console.log('passively stop reason:', reason);
    }, []);
    const onDeviceChange = useCallback(() => {
        if (mediaStream) {
            setMicList(mediaStream.getMicList());
            setSpeakerList(mediaStream.getSpeakerList());
            if (!isAndroidOrIOSBrowser()) {
                setCameraList(mediaStream.getCameraList());
            }
            setActiveMicrophone(mediaStream.getActiveMicrophone());
            setActiveSpeaker(mediaStream.getActiveSpeaker());
            setActiveCamera(mediaStream.getActiveCamera());
        }
    }, [mediaStream]);

    const onRecordingChange = useCallback(() => {
        setRecordingStatus(recordingClient?.getCloudRecordingStatus() || '');
    }, [recordingClient]);

    const onRecordingISOChange = useCallback(
        (payload: any) => {
            if (payload?.userId === zmClient.getSessionInfo().userId || payload?.status === RecordingStatus.Ask) {
                setRecordingIsoStatus(payload?.status);
            }
            console.log('recording-iso-change', payload);
        },
        [zmClient]
    );

    const onDialOutChange = useCallback((payload: any) => {
        setPhoneCallStatus(payload.code);
    }, []);

    const onRecordingClick = async (key: string) => {
        switch (key) {
            case 'Record': {
                await recordingClient?.startCloudRecording();
                break;
            }
            case 'Resume': {
                await recordingClient?.resumeCloudRecording();
                break;
            }
            case 'Stop': {
                await recordingClient?.stopCloudRecording();
                break;
            }
            case 'Pause': {
                await recordingClient?.pauseCloudRecording();
                break;
            }
            case 'Status': {
                break;
            }
            default: {
                await recordingClient?.startCloudRecording();
            }
        }
    };
    const onVideoCaptureChange = useCallback((payload: any) => {
        if (payload.state === VideoCapturingState.Started) {
            setIsStartedVideo(true);
        } else {
            setIsStartedVideo(false);
        }
    }, []);
    const onShareAudioChange = useCallback(
        (payload: any) => {
            const { state } = payload;
            if (state === 'on') {
                if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
                    setIsComputerAudioDisabled(true);
                }
            } else if (state === 'off') {
                setIsComputerAudioDisabled(false);
            }
        },
        [mediaStream]
    );
    const onHostAskToUnmute = useCallback((payload: any) => {
        const { reason } = payload;
        console.log(`Host ask to unmute the audio.`, reason);
        mediaStream?.unmuteAudio();
    }, []);

    const onCaptionStatusChange = useCallback((payload: any) => {
        const { autoCaption } = payload;
        if (autoCaption) {
            message.info('Auto live transcription enabled!');
        }
    }, []);

    const onCaptionMessage = useCallback((payload: any) => {
        const { text, done } = payload;
        setCaption({
            text,
            isOver: done
        });
    }, []);

    const onCaptionDisable = useCallback((payload: any) => {
        setIsDisableCaptions(payload);
        if (payload) {
            setIsStartedLiveTranscription(false);
        }
    }, []);

    const onCanSeeMyScreen = useCallback(() => {
        message.info('Users can now see your screen', 1);
    }, []);
    const onSelectVideoPlayback = useCallback(
        async (url: string) => {
            if (activePlaybackUrl !== url) {
                await mediaStream?.switchCamera({ url, loop: true });
                if (isStartedAudio) {
                    await mediaStream?.switchMicrophone({ url, loop: true });
                } else {
                    await mediaStream?.startAudio({ mediaFile: { url, loop: true } });
                }
                setActivePlaybackUrl(url);
            }
        },
        [isStartedAudio, activePlaybackUrl, mediaStream]
    );

    const onLiveStreamClick = useCallback(() => {
        if (liveStreamStatus === LiveStreamStatus.Ended) {
            setLiveStreamVisible(true);
        } else if (liveStreamStatus === LiveStreamStatus.InProgress) {
            liveStreamClient?.stopLiveStream();
        }
    }, [liveStreamStatus, liveStreamClient]);
    const onLiveStreamStatusChange = useCallback((status: any) => {
        setLiveStreamStatus(status);
        if (status === LiveStreamStatus.Timeout) {
            message.error('Start live streaming timeout');
        }
    }, []);
    useEffect(() => {
        zmClient.on('current-audio-change', onHostAudioMuted);
        zmClient.on('passively-stop-share', onPassivelyStopShare);
        zmClient.on('device-change', onDeviceChange);
        zmClient.on('recording-change', onRecordingChange);
        zmClient.on('individual-recording-change', onRecordingISOChange);
        zmClient.on('dialout-state-change', onDialOutChange);
        zmClient.on('video-capturing-change', onVideoCaptureChange);
        zmClient.on('share-audio-change', onShareAudioChange);
        zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
        zmClient.on('caption-status', onCaptionStatusChange);
        zmClient.on('caption-message', onCaptionMessage);
        zmClient.on('caption-host-disable', onCaptionDisable);
        zmClient.on('share-can-see-screen', onCanSeeMyScreen);
        zmClient.on('live-stream-status', onLiveStreamStatusChange);
        return () => {
            zmClient.off('current-audio-change', onHostAudioMuted);
            zmClient.off('passively-stop-share', onPassivelyStopShare);
            zmClient.off('device-change', onDeviceChange);
            zmClient.off('recording-change', onRecordingChange);
            zmClient.off('individual-recording-change', onRecordingISOChange);
            zmClient.off('dialout-state-change', onDialOutChange);
            zmClient.off('video-capturing-change', onVideoCaptureChange);
            zmClient.off('share-audio-change', onShareAudioChange);
            zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
            zmClient.off('caption-status', onCaptionStatusChange);
            zmClient.off('caption-message', onCaptionMessage);
            zmClient.off('caption-host-disable', onCaptionDisable);
            zmClient.off('share-can-see-screen', onCanSeeMyScreen);
            zmClient.off('live-stream-status', onLiveStreamStatusChange);
        };
    }, [
        zmClient,
        onHostAudioMuted,
        onPassivelyStopShare,
        onDeviceChange,
        onRecordingChange,
        onDialOutChange,
        onVideoCaptureChange,
        onShareAudioChange,
        onHostAskToUnmute,
        onCaptionStatusChange,
        onCaptionMessage,
        onCanSeeMyScreen,
        onRecordingISOChange,
        onCaptionDisable,
        onLiveStreamStatusChange
    ]);
    useUnmount(() => {
        if (zmClient.getSessionInfo().isInMeeting) {
            if (isStartedAudio) {
                mediaStream?.stopAudio();
            }
            if (isStartedVideo) {
                mediaStream?.stopVideo();
            }
            mediaStream?.stopShareScreen();
        }
    });
    useMount(() => {
        if (mediaStream) {
            setIsSupportPhone(!!mediaStream.isSupportPhoneFeature());
            setPhoneCountryList(mediaStream.getSupportCountryInfo() || []);
            setSharePrivileg(mediaStream.getSharePrivilege());
            if (isAndroidOrIOSBrowser()) {
                setCameraList([
                    { deviceId: MobileVideoFacingMode.User, label: 'Front-facing' },
                    { deviceId: MobileVideoFacingMode.Environment, label: 'Rear-facing' }
                ]);
            }
        }
    });

    /* tuyen code */
    // tắt mở all mic
    const onClickSwitchMute = useCallback(() => {
        let host = zmClient.getAllUser().find(u => u.isHost === true);
        let objectCfg;
        try {
            objectCfg = JSON.parse(host?.displayName ?? "");
        } catch (error) {
            objectCfg = {
                idsPin: [],
                idsAudio: [],
                idsVideo: [],
                userName: host?.displayName,
                allowAllAudio: false, // true cho tất cả nói
                avatar: host?.avatar ?? null
            }
        }

        objectCfg.idsAudio = isAllMute ? objectCfg.idsAudio : [];
        objectCfg.allowAllAudio = isAllMute ? true : false;
        let changeName = JSON.stringify(objectCfg);

        zmClient.changeName(changeName, host?.userId)
            .then(() => {
                isAllMute ? message.success("Mic của tất cả thành viên đã được cho phép mở") : message.success("Đã tắt mic tất cả user");
            })
            .catch((error) => {
                message.error("Có lỗi xảy ra. Vui lòng thử lại!");
            });

        if (isAllMute) {
            mediaStream?.unmuteAllAudio();
            setIsAllMute(false);
        } else {
            mediaStream?.muteAllAudio();
            setIsAllMute(true);
        }
    }, [isAllMute, mediaStream]);

    // Thông báo có user giơ tay xin phát biểu
    useEffect(() => {
        records.forEach(record => {
            let messages = Array.isArray(record.message) ? record.message : [record.message];
            messages.forEach((text, index) => {
                try {
                    let objectText = JSON.parse(text ?? '');
                    if (objectText.type === 1) {
                        let sender = JSON.parse(objectText.user);
                        const messageKey = `${sender.userName}-${record.timestamp}-${index}`; // Tạo một khóa duy nhất cho mỗi tin nhắn
                        // Kiểm tra xem tin nhắn đã được hiển thị chưa
                        if (!displayedMessages.has(messageKey)) {
                            message.open({
                                content: `${sender.userName} Giơ tay xin phát biểu`,
                                duration: 3,
                                icon: iconRaiseHand,
                                style: {
                                    marginTop: '20vh'
                                }
                            });

                            // Thêm tin nhắn vào danh sách đã hiển thị
                            setDisplayedMessages(prev => new Set(prev).add(messageKey));
                        }
                    }
                } catch (error) {
                    // console.error('Error parsing message:', error);
                }
            })
        })
    }, [records]);

    const [usersSet, setUsers] = useState(new Set());
    const soundJoin = new Audio('/sound/discord-join.mp3');

    // thông báo có user mới tham gia zoom
    useEffect(() => {
        sortedParticipants.forEach((u) => {
            let userKey = u.userGuid;
            if (!usersSet.has(userKey)) {
                let userName;
                try {
                    let objectName = JSON.parse(u.displayName);
                    userName = objectName.userName as string;
                } catch (error) {
                    userName = u.displayName;
                }
                soundJoin.play().catch((error) => {
                    console.error("Lỗi phát âm thanh " + error);
                });
                message.info(`${userName} mới tham gia.`);
                setUsers((prev) => new Set(prev).add(userKey));
            }
        });

        setIsAllMute(sortedParticipants.filter(user => !user.isHost).some((user) => user?.muted === false) ? false : true );
    }, [sortedParticipants, usersSet])

    const recordingButtons: RecordButtonProps[] = getRecordingButtons(recordingStatus, zmClient.isHost());
    return (
        <div className={classNames('video-footer', className)}>
            
            <Tooltip title="Danh sách tham gia">
                <Button
                    className={classNames('ant-btn-dashed', ' vc-button')}
                    icon={<TeamOutlined />}
                    ghost={true}
                    shape="circle"
                    size="small"
                    onClick={() => { drawers.drawerUserVisible ? closeDrawer('drawerUserVisible') : showDrawer('drawerUserVisible') }}
                />
            </Tooltip>

            <Tooltip title="Đổi tên">
                <Button
                    className={classNames('ant-btn-dashed', ' vc-button')}
                    icon={<EditOutlined />}
                    ghost={true}
                    shape="circle"
                    size="small"
                    onClick={showModal}
                />
            </Tooltip>

            <Tooltip title="Chat">
                <Button
                    className={classNames('vc-button')}
                    icon={<WechatOutlined />}
                    ghost={true}
                    shape="circle"
                    size="large"
                    onClick={() => { drawers.drawerChatVisible ? closeDrawer('drawerChatVisible') : showDrawer('drawerChatVisible') }}
                />
            </Tooltip>

            {isHostOrManager && (
                <Tooltip title={`${isAllMute ? 'Bật tất cả mic' : 'Tắt tất cả mic'}`}>
                    <Button
                        className={classNames('vc-button')}
                        icon={isAllMute ? <UsergroupAddOutlined /> : <UsergroupDeleteOutlined />}
                        ghost={true}
                        shape="circle"
                        size="large"
                        onClick={onClickSwitchMute}
                    />
                </Tooltip>
            )}

            {isAudioEnable && (
                <MicrophoneButton
                    isStartedAudio={isStartedAudio}
                    isMuted={isMuted}
                    isSupportPhone={isSupportPhone}
                    audio={audio}
                    phoneCountryList={phoneCountryList}
                    onPhoneCallClick={onPhoneCall}
                    onPhoneCallCancel={onPhoneCallCancel}
                    phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
                    onMicrophoneClick={onMicrophoneClick}
                    onMicrophoneMenuClick={onMicrophoneMenuClick}
                    microphoneList={micList}
                    speakerList={speakerList}
                    activeMicrophone={activeMicrophone}
                    activeSpeaker={activeSpeaker}
                    disabled={isComputerAudioDisabled}
                    isMicrophoneForbidden={isMicrophoneForbidden}
                    isSecondaryAudioStarted={isSecondaryAudioStarted}
                />
            )}
            <CameraButton
                isStartedVideo={isStartedVideo}
                onCameraClick={onCameraClick}
                onSwitchCamera={onSwitchCamera}
                onMirrorVideo={onMirrorVideo}
                onVideoStatistic={() => {
                    setSelectedStatisticTab('video');
                    setStatisticVisible(true);
                }}
                onBlurBackground={onBlurBackground}
                onSelectVideoPlayback={onSelectVideoPlayback}
                activePlaybackUrl={activePlaybackUrl}
                cameraList={cameraList}
                activeCamera={activeCamera}
                isMirrored={isMirrored}
                isBlur={isBlur}
                isBackGround={isBackGround}
                onUpdateBackground={onUpdateBackground}
            />
            {sharing && (
                <ScreenShareButton
                    sharePrivilege={sharePrivilege}
                    isHostOrManager={zmClient.isHost() || zmClient.isManager()}
                    onScreenShareClick={onScreenShareClick}
                    onSharePrivilegeClick={async (privilege) => {
                        await mediaStream?.setSharePrivilege(privilege);
                        setSharePrivileg(privilege);
                    }}
                />
            )}
            {recordingButtons.map((button: RecordButtonProps) => {
                return (
                    <RecordingButton
                        key={button.text}
                        onClick={() => {
                            onRecordingClick(button.text);
                        }}
                        {...button}
                    />
                );
            })}
            {liveTranscriptionClient?.getLiveTranscriptionStatus().isLiveTranscriptionEnabled && (
                <>
                    <LiveTranscriptionButton
                        isStartedLiveTranscription={isStartedLiveTranscription}
                        isDisableCaptions={isDisableCaptions}
                        isHost={zmClient.isHost()}
                        onDisableCaptions={onDisableCaptions}
                        onLiveTranscriptionClick={onLiveTranscriptionClick}
                    />
                    <TranscriptionSubtitle text={caption.text} />
                </>
            )}
            {liveStreamClient?.isLiveStreamEnabled() && zmClient.isHost() && (
                <>
                    <LiveStreamButton
                        isLiveStreamOn={liveStreamStatus === LiveStreamStatus.InProgress}
                        onLiveStreamClick={onLiveStreamClick}
                    />
                    <LiveStreamModal
                        visible={liveStreamVisible}
                        setVisible={setLiveStreamVisible}
                        onStartLiveStream={(streanUrl: string, streamKey: string, broadcastUrl: string) => {
                            liveStreamClient.startLiveStream(streanUrl, streamKey, broadcastUrl);
                        }}
                    />
                </>
            )}
            {liveStreamStatus === LiveStreamStatus.InProgress && (
                <IconFont type="icon-live" style={{ position: 'fixed', top: '45px', left: '10px', color: '#f00' }} />
            )}
            {isSecondaryAudioStarted && (
                <Tooltip title="Secondary audio on">
                    <SoundOutlined style={{ position: 'fixed', top: '45px', left: '10px', color: '#f60', fontSize: '24px' }} />
                </Tooltip>
            )}
            <LeaveButton onLeaveClick={onLeaveClick} isHost={zmClient.isHost()} onEndClick={onEndClick} />

            <AudioVideoStatisticModal
                visible={statisticVisible}
                setVisible={setStatisticVisible}
                defaultTab={selecetedStatisticTab}
                isStartedAudio={isStartedAudio}
                isMuted={isMuted}
                isStartedVideo={isStartedVideo}
            />

            {recordingIsoStatus === RecordingStatus.Ask && (
                <IsoRecordingModal
                    onClick={() => {
                        recordingClient?.acceptIndividualRecording();
                    }}
                    onCancel={() => {
                        recordingClient?.declineIndividualRecording();
                    }}
                />
            )}
            {!mediaStream?.isSupportVirtualBackground() && (
                <VideoMaskModel visible={videoMaskVisible} setVisible={setVideoMaskVisible} isMirrored={isMirrored} />
            )}

            <Modal title="Đổi tên" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <h3>Tên hiện tại: {getUserName()} </h3>
                <Input placeholder="Nhập tên mới ... " value={userName}
                    onChange={(e) => setUserName(e.target.value)} ></Input>
            </Modal>

            <Drawer
                title={<div style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>Phòng chat</div>}
                style={{ borderRadius: '16px' }}
                bodyStyle={{ padding: 0 }}
                mask={false}
                width="30%"
                open={drawers.drawerChatVisible}
                onClose={() => closeDrawer('drawerChatVisible')}
            >
                <Chat
                    chatUser={chatUser}
                    downloadFile={downloadFile}
                    isHostOrManager={isHostOrManager}
                    privilege={privilege}
                    receivers={receivers}
                    records={records}
                    resendFile={resendFile}
                    sendFile={sendFile}
                    sendMessage={sendMessage}
                    setChatUserId={setChatUserId}
                />
            </Drawer>

            <Drawer
                title={<div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Danh sách tham gia</div>}
                open={drawers.drawerUserVisible}
                onClose={() => closeDrawer('drawerUserVisible')}
                width="30%"
                bodyStyle={{ padding: 2 }}
                mask={false}
            >

                <div style={{ height: '100%', width: 'auto', maxHeight: '100vh', overflow: 'scroll' }}>
                    <ul className="user-list">
                        {sortedParticipants.sort((user1, user2) => {
                            if (user1.muted === undefined) return 1;
                            if (user2.muted === undefined) return -1;
                            return user1.muted === user2.muted ? 0 : user1.muted ? 1 : -1;
                            }).map((user) => {
                            return (
                                <ParticipantItem
                                key={user.userId}
                                user={user}
                                bVideoOn={user.bVideoOn}
                                userId={user.userId} 
                                isMuted={!!user?.muted} 
                                isStartedAudio={user?.audio !== ''} 
                                audio={user?.audio} 
                                isMicrophoneForbidden={false} />
                            );
                        })}
                    </ul>
                </div>
            </Drawer>
        </div>
    );
};
export default VideoFooter;
