/* eslint-disable prettier/prettier */
import { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { Slider, Dropdown, Button, message } from 'antd';
import { CheckOutlined, MoreOutlined, PushpinOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import AvatarActionContext from '../context/avatar-context';
import ZoomContext from '../../../context/zoom-context';
import MediaContext from '../../../context/media-context';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
import { useSpotlightVideo } from '../hooks/useSpotlightVideo';
import { Participant } from '../../../index-types';
import { useAudioLevel } from '../hooks/useAudioLevel';
import { IconFont } from '../../../component/icon-font';
import { AudoiAnimationIcon } from '../../../component/audio-animation-icon';
interface ParticipantItemProps {
    className?: string;
    user: Participant;
    userId: number;
    isMuted?: boolean;
    isStartedAudio?: boolean;
    audio?: string;
    isMicrophoneForbidden?: boolean;
    bVideoOn: boolean;
}

const ParticipantItem = (props: ParticipantItemProps) => {
    const { userId, className, isMuted, isStartedAudio, audio, isMicrophoneForbidden, user, bVideoOn } = props;
    // const { avatarActionState, dispatch } = useContext(AvatarActionContext);
    const { mediaStream } = useContext(MediaContext);
    const zmClient = useContext(ZoomContext);
    const [isDropdownVisible, setIsDropdownVisbile] = useState(false);
    const [isControllingRemoteCamera, setIsControllingRemoteCamera] = useState(false);

    const menu = [];
    let host = zmClient.getAllUser().find((u) => u.isHost === true);
    let isPin = false;
    try {
        let object = JSON.parse(host?.displayName ?? '');
        let idsPin = object.idsPin as string[];
        isPin = idsPin.includes(userId.toString());
    } catch (error) {
        isPin = false;
    }

    if (zmClient?.getCurrentUserInfo() && zmClient?.getCurrentUserInfo().isHost && host?.userId !== userId) {
        menu.push(getAntdItem('Kick user', 'kick'));
    }

    if (isPin) {
        menu.push(getAntdItem('Bỏ ghim user', 'unpin'));
    } else {
        menu.push(getAntdItem('Ghim user', 'pin'));
    }

    if (zmClient.getUser(userId)?.muted === false) {
        menu.push(getAntdItem('Tắt mic', 'mute'));
    } else if (zmClient.getUser(userId)?.muted === true) {
        menu.push(getAntdItem('Bật mic', 'unmute'));
    }

    const level = useAudioLevel();
    const [audioIcon, setAudioIcon] = useState<JSX.Element | null>(null);
    useEffect(() => {
        let iconType = '';
        if (isStartedAudio) {
            if (isMuted) {
                if (audio === 'phone') {
                    iconType = 'icon-phone-off';
                } else {
                    iconType = 'icon-audio-muted';
                }
            } else {
                if (audio === 'phone') {
                    iconType = 'icon-phone';
                } else {
                    if (level !== 0) {
                        // iconType = 'icon-audio-animation';
                        setAudioIcon(<AudoiAnimationIcon className="user-action-mic-info" level={level} />);
                    } else {
                        iconType = 'icon-audio-unmuted';
                    }
                }
            }
        } else {
            if (isMicrophoneForbidden) {
                iconType = 'icon-audio-disallow';
            } else {
                iconType = 'icon-headset';
            }
        }
        if (iconType) {
            setAudioIcon( <IconFont type={iconType} style={{ color: 'black' }} />);
        }
    }, [level, isMuted, isStartedAudio, audio, isMicrophoneForbidden]);

    const onDropDownVisibleChange = useCallback((visible: boolean) => {
        setIsDropdownVisbile(visible);
    }, []);

    const changeUserName = (newName: string, userId: any, retryCount = 3) => {
        zmClient
            .changeName(newName, userId)
            .then(() => {
                message.success('Tắt mic thành công');
            })
            .catch((error) => {
                if (retryCount > 0) {
                    console.warn(`Thử lại lần ${4 - retryCount}, lỗi: ${error}`);
                    changeUserName(newName, userId, retryCount - 1); // Gọi lại hàm và giảm số lần thử lại
                } else {
                    message.error('Tắt mic thất bại sau nhiều lần thử: ' + error);
                }
            });
    };

    const onMenuItemClick = useCallback(
        ({ key }: { key: string }) => {
            let host = zmClient.getAllUser().find((u) => u.isHost === true);
            let user = zmClient.getUser(userId);
            if (key === 'removeSpotlight') {
                mediaStream?.removeSpotlightedVideo(userId);
            } else if (key === 'addSpotlight') {
                mediaStream?.spotlightVideo(userId, false);
            } else if (key === 'replaceSpotlight') {
                mediaStream?.spotlightVideo(userId, true);
            } else if (key === 'unmute') {
                let changeName = '';
                try {
                    let userName = host?.displayName ?? '';
                    let object = JSON.parse(userName);
                    if (object?.idsAudio && !object.idsAudio.includes(userId.toString())) {
                        // let infoHost = {
                        //     idsPin: [...object.idsPin],
                        //     idsAudio: [...object.idsAudio, userId.toString()],
                        //     idsVideo: [...object.idsVideo],
                        //     userName: object.userName,
                        //     allowAllAudio: object.allowAllAudio, // true cho tất cả nói
                        //     avatar: object.avatar ?? null
                        // };
                        let infoHost = {
                            ...object,
                            idsAudio: [...object.idsAudio, userId.toString()]
                        };
                        changeName = JSON.stringify(infoHost) ?? '';
                    }
                } catch (error) {
                    let displayName = {
                        idsPin: [],
                        idsAudio: [userId.toString()],
                        idsVideo: [],
                        userName: host?.displayName,
                        allowAllAudio: false, // true cho tất cả nói
                        avatar: host?.avatar ?? null
                    };
                    changeName = JSON.stringify(displayName) ?? '';
                }

                if (user && user.isManager === false && user.isHost === false) {
                    zmClient
                        .changeName(changeName, host?.userId)
                        .then(() => {
                            message.success('Bật mic thành công');
                        })
                        .catch((error) => {
                            message.error('Có lỗi xảy ra!');
                        });
                } else {
                    mediaStream
                        ?.unmuteAudio(userId)
                        .then(() => {
                            message.success('Bật mic thành công');
                        })
                        .catch(() => {
                            message.error('Bật mic thất bại');
                        });
                }
            } else if (key === 'mute') {
                try {
                    if (user && user.isManager === false && user.isHost === false) {
                        let userName = host?.displayName ?? '';
                        let object = JSON.parse(userName);
                        let indexDelete = object.idsAudio.indexOf(userId.toString());
                        if (object?.idsAudio && indexDelete > -1) {
                            object.idsAudio.splice(indexDelete, 1);
                            let changeName = JSON.stringify(object);
                            let hostId = host?.userId ?? 0;
                            zmClient
                                .changeName(changeName, hostId)
                                .then(() => {
                                    message.success('Tắt mic thành công');
                                })
                                .catch((error) => {
                                    message.success('Tắt mic thất bại ' + error);
                                });
                        }
                    }
                    mediaStream
                        ?.muteAudio(userId)
                        .then(() => {
                            // message.success("Tắt mic thành công");
                        })
                        .catch(() => {
                            message.error('Tắt mic thất bại');
                        });
                } catch (error) {
                    let displayName = {
                        idsPin: [],
                        idsAudio: [userId.toString()],
                        idsVideo: [],
                        userName: host?.displayName,
                        allowAllAudio: false, // true cho tất cả nói
                        avatar: host?.avatar ?? null
                    };
                    let changeName = JSON.stringify(displayName) ?? '';
                    zmClient
                        .changeName(changeName, host?.userId)
                        .then(() => {
                            message.success('Tắt mic thành công');
                        })
                        .catch((error) => {
                            message.success('Tắt mic thất bại ' + error);
                        });
                }
            }
            //   else if(key ==='muteAll'){
            //     mediaStream?.muteAllAudio();
            //   }
            else if (key === 'pin') {
                try {
                    // if (user && user.isManager === false && user.isHost === false) {
                    let userName = host?.displayName ?? '';
                    let object = JSON.parse(userName);
                    if (object?.idsPin && !object.idsPin.includes((i: number) => i === userId)) {
                        // let infoHost = {
                        //     idsPin: [...object.idsPin, userId.toString()],
                        //     idsAudio: [...object.idsAudio],
                        //     idsVideo: [...object.idsVideo],
                        //     userName: object.userName,
                        //     allowAllAudio: object.allowAllAudio, // true cho tất cả nói
                        //     avatar: object.avatar ?? ''
                        // };
                        let infoHost = {
                            ...object,
                            idsPin: [...object.idsPin, userId.toString()]
                        };
                        let changeName = JSON.stringify(infoHost);
                        zmClient
                            .changeName(changeName, host?.userId)
                            .then(() => {
                                message.success('Ghim thành công');
                            })
                            .catch((error) => {
                                message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
                            });
                    }
                    // }
                } catch (error) {
                    let displayName = {
                        idsPin: [userId.toString()],
                        idsAudio: [],
                        idsVideo: [],
                        userName: host?.displayName,
                        allowAllAudio: false,
                        avatar: host?.avatar ?? ''
                    };
                    let changeName = JSON.stringify(displayName);
                    zmClient
                        .changeName(changeName, host?.userId)
                        .then(() => {
                            message.success('Ghim thành công');
                        })
                        .catch((error) => {
                            message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
                        });
                }
            } else if (key === 'unpin') {
                try {
                    // if (user && user.isManager === false && user.isHost === false) {
                    let userName = host?.displayName ?? '';
                    let object = JSON.parse(userName);
                    let indexDelete = object.idsPin.indexOf(userId.toString());
                    if (object?.idsPin && indexDelete > -1) {
                        object.idsPin.splice(indexDelete, 1);
                        let changeName = JSON.stringify(object);
                        zmClient
                            .changeName(changeName, host?.userId)
                            .then(() => {
                                message.success('Bỏ ghim thành công');
                            })
                            .catch((error) => {
                                message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
                            });
                    }
                    // }
                } catch (error) {
                    let displayName = {
                        idsPin: [],
                        idsAudio: [],
                        idsVideo: [],
                        userName: host?.displayName,
                        allowAllAudio: false,
                        avatar: host?.avatar ?? ''
                    };
                    let changeName = JSON.stringify(displayName);
                    zmClient
                        .changeName(changeName, host?.userId)
                        .then(() => {
                            message.success('Bỏ ghim thành công');
                        })
                        .catch((error) => {
                            message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
                        });
                }
            } else if (key === 'kick') {
                zmClient.removeUser(userId)
                    .then(() => {
                        message.success("Kick user thành công");
                    })
                    .catch((error) => {
                        console.log('error: ', error);

                        message.success("Có lỗi xảy ra. Vui lòng thử lại");
                    });
            }

            setIsDropdownVisbile(false);
        },
        [mediaStream, userId, isControllingRemoteCamera]
    );

    const handleUserName = (text: string): string => {
        let name;
        try {
            let objectName = JSON.parse(text);
            name = objectName.userName as string;
        } catch (error) {
            name = text;
        }
        return name;
    }

    return (
        <div className="user-item" key={user.userId}>
            <div className="user-avatar">
                {
                    user.avatar ? <img src={user.avatar} className="user-avatar-img" alt="" /> : <UserOutlined className='user-avatar-img' />
                }
            </div>
            <div className="user-name">
                {handleUserName(user.displayName)}
            </div>
            <div className="user-action">
                {isPin ? <PushpinOutlined className='user-action-pin'/> : null}
                <div className='user-action-mic'>
                    {audioIcon}
                </div>
                <div className='user-action-camera'>
                    {
                        bVideoOn ? <VideoCameraOutlined /> : <img className='img' src="/images/no-video.png" alt="camera off" />
                    }
                </div>
                <div className='user-action-option'>
                    {
                        menu.length > 0 && (
                            <Dropdown
                                menu={getAntdDropdownMenu(menu, onMenuItemClick)}
                                placement="bottomRight"
                                trigger={['click']}
                                onOpenChange={onDropDownVisibleChange}
                            >
                                <Button
                                    icon={<MoreOutlined />}
                                    className={classNames('more-button', {
                                        'more-button-active': isDropdownVisible
                                    })}
                                    size="small"
                                />
                            </Dropdown>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ParticipantItem;
