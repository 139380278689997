/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { ChatRecord } from '../chat-types';
import ChatFileMessageItem from './chat-file-message-item';
import './chat-message-item.scss';
interface ChatMessageItemProps {
    record: ChatRecord;
    currentUserId: number;
    setChatUser: (userId: number) => void;
    resendFile: (retryToken: string, fileUuid: string) => void;
    downloadFile: (id: string) => void;
}
const ChatMessageItem = (props: ChatMessageItemProps) => {
    const { record, currentUserId, setChatUser, resendFile, downloadFile } = props;
    let { message, file, sender, receiver, timestamp } = record;
    let { avatar } = sender;
    let { name } = sender;
    let receiverName = receiver.name;
    const isCurrentUser = currentUserId === sender.userId;
    const onAvatarClick = useCallback(() => {
        if (!isCurrentUser) {
            setChatUser(sender.userId);
        }
    }, [isCurrentUser, sender, setChatUser]);
    const chatMessage = Array.isArray(message) ? message : [message];

    try {
        if (receiver.userId !== 0) {
            let jsonReceiver = JSON.parse(receiver.name) ?? null;
            if (jsonReceiver) {
                receiverName = jsonReceiver.userName;
            }
        }
    } catch (error) {
        // console.log(error);
    }

    try {
        if (!isCurrentUser) {
            let objectJson = JSON.parse(sender.name) ?? null;
            if (objectJson) {
                name = objectJson.userName;
                avatar = objectJson.avatar;
            }
        }
    } catch (error) {
        // console.log(error);
    }

    // const [chatItems, setChatItems] = useState<{ content: string, status: boolean }[]>([]);
    const chatItems = useMemo(() => {
       return chatMessage.map(chat => {
            let text = chat ?? "";
            try {
                let objectText = JSON.parse(text);
                return { content: objectText.content, status: true };
            } catch (error) {
                return{ content: text, status: false  };
            }
        })

    }, [chatMessage]);

    return (
        <div className={classNames('chat-message-item', { myself: isCurrentUser })}>
            <Button className="chat-message-avatar" onClick={onAvatarClick} ghost shape="circle" size="large">
                {avatar ? <img src={avatar} className="chat-message-avatar-img" alt="" /> : <UserOutlined />}
            </Button>
            <div className="chat-message-content">
                <div className={classNames('chat-message-info', { myself: isCurrentUser })}>
                    <p className="chat-message-receiver">
                        {isCurrentUser ? '' : name}
                        <span>To</span>
                        <a
                            href="#"
                            onClick={(event) => {
                                event.preventDefault();
                                setChatUser(receiver.userId);
                            }}
                        >
                            {receiver.userId === currentUserId ? 'me' : receiverName}
                        </a>
                    </p>
                    <p className="chat-message-time">{new Date(timestamp).toLocaleTimeString()}</p>
                </div>
                {file ? (
                    <ChatFileMessageItem
                        className={classNames({ myself: isCurrentUser })}
                        file={file}
                        id={record.id}
                        resendFile={resendFile}
                        downloadFile={downloadFile}
                    />
                ) : (
                    <ul className={classNames('chat-message-text-list', { myself: isCurrentUser })}>
                        {chatItems.map((chat, index) => (
                            <li className={classNames('chat-message-text', {'raise-hand': chat.status})} key={index}>
                                {chat.content}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};
export default ChatMessageItem;
