/* eslint-disable prettier/prettier */
import { useState, useCallback, useContext, useEffect } from 'react';
import { Slider, Dropdown, Button, message } from 'antd';
import { CheckOutlined, MoreOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import AvatarActionContext from '../context/avatar-context';
import ZoomContext from '../../../context/zoom-context';
import MediaContext from '../../../context/media-context';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
import { useSpotlightVideo } from '../hooks/useSpotlightVideo';
interface AvatarMoreProps {
  className?: string;
  userId: number;
  isHover: boolean;
}
const isUseVideoPlayer = new URLSearchParams(location.search).get('useVideoPlayer') === '1';
const AvatarMore = (props: AvatarMoreProps) => {
  const { userId, isHover } = props;
  const { avatarActionState, dispatch } = useContext(AvatarActionContext);
  const { mediaStream } = useContext(MediaContext);
  const zmClient = useContext(ZoomContext);
  const [isDropdownVisible, setIsDropdownVisbile] = useState(false);
  const [isControllingRemoteCamera, setIsControllingRemoteCamera] = useState(false);
  useSpotlightVideo(zmClient, mediaStream, (participants) => {
    dispatch({ type: 'set-spotlighted-videos', payload: participants });
  });
  const actionItem = avatarActionState[`${userId}`];
  const { spotlightedUserList } = avatarActionState;

  const menu = [];
  if (actionItem) {
    let host = zmClient.getAllUser().find((u) => u.isHost === true);
    let isPin = false;
    try {
      let object = JSON.parse(host?.displayName ?? '');
      let idsPin = object.idsPin as string[];
      isPin = idsPin.includes(userId.toString());
    } catch (error) {
      isPin = false;
    }

    if (zmClient?.getCurrentUserInfo() && zmClient?.getCurrentUserInfo().isHost && host?.userId !== userId) {
      menu.push(getAntdItem('Kick user', 'kick'));
    }

    if (isPin) {
      menu.push(getAntdItem('Bỏ ghim user', 'unpin'));
    } else {
      menu.push(getAntdItem('Ghim user', 'pin'));
    }

    if (zmClient.getUser(userId)?.muted === false) {
      menu.push(getAntdItem('Tắt mic', 'mute'));
    } else if (zmClient.getUser(userId)?.muted === true) {
      menu.push(getAntdItem('Bật mic', 'unmute'));
    }

    // if (actionItem.localVolumeAdjust.enabled) {
    //   menu.push(
    //     getAntdItem('Adjust volume locally', 'volume', actionItem?.localVolumeAdjust.toggled && <CheckOutlined />)
    //   );
    // }
    // if (actionItem?.farEndCameraControl.enabled) {
    //   menu.push(getAntdItem(isControllingRemoteCamera ? 'Give up camera control' : 'Control far end camera', 'farend'));
    // }
  }
  if (actionItem?.videoResolutionAdjust.enabled) {
    menu.push(
      getAntdItem(
        'Subscribe other video resolution',
        'subscribeVideoQuality',
        actionItem?.videoResolutionAdjust.toggled && <CheckOutlined />
      )
    );
  }
  // if (isUseVideoPlayer) {
  //     const currentUserId = zmClient.getCurrentUserInfo()?.userId;
  //     const isHostOrManager = zmClient.isHost() || zmClient.isManager();
  //     if (
  //         currentUserId === userId &&
  //         spotlightedUserList?.find((user) => user.userId === currentUserId) &&
  //         spotlightedUserList.length === 1
  //     ) {
  //         menu.push(getAntdItem('Remove spotlight', 'removeSpotlight'));
  //     } else if (isHostOrManager) {
  //         if (spotlightedUserList && spotlightedUserList.findIndex((user) => user.userId === userId) > -1) {
  //             menu.push(getAntdItem('Remove spotlight', 'removeSpotlight'));
  //         } else {
  //             const user = zmClient.getUser(userId);
  //             if (user?.bVideoOn) {
  //                 menu.push(getAntdItem('Add spotlight', 'addSpotlight'));
  //                 menu.push(getAntdItem('Replace spotlight', 'replaceSpotlight'));
  //             }
  //         }
  //     }
  // }

  const onSliderChange = useCallback(
    (value: any) => {
      mediaStream?.adjustUserAudioVolumeLocally(userId, value);
      dispatch({ type: 'update-local-volume', payload: { userId, volume: value } });
    },
    [userId, mediaStream, dispatch]
  );
  const onDropDownVisibleChange = useCallback((visible: boolean) => {
    setIsDropdownVisbile(visible);
  }, []);

  const changeUserName = (newName: string, userId: any, retryCount = 3) => {
    zmClient
      .changeName(newName, userId)
      .then(() => {
        message.success('Tắt mic thành công');
      })
      .catch((error) => {
        if (retryCount > 0) {
          console.warn(`Thử lại lần ${4 - retryCount}, lỗi: ${error}`);
          changeUserName(newName, userId, retryCount - 1); // Gọi lại hàm và giảm số lần thử lại
        } else {
          message.error('Tắt mic thất bại sau nhiều lần thử: ' + error);
        }
      });
  };

  const onMenuItemClick = useCallback(
    ({ key }: { key: string }) => {
      let host = zmClient.getAllUser().find((u) => u.isHost === true);
      let user = zmClient.getUser(userId);
      if (key === 'volume') {
        dispatch({ type: 'toggle-local-volume', payload: { userId } });
      } else if (key === 'farend') {
        dispatch({ type: 'toggle-far-end-camera-control', payload: { userId } });
        if (isControllingRemoteCamera) {
          mediaStream?.giveUpFarEndCameraControl(userId);
          dispatch({ type: 'set-is-controlling-remote-camera', payload: false });
        } else {
          mediaStream?.requestFarEndCameraControl(userId);
        }
        setIsControllingRemoteCamera(!isControllingRemoteCamera);
      } else if (key === 'subscribeVideoQuality') {
        dispatch({ type: 'toggle-video-resolution-adjust', payload: { userId } });
      } else if (key === 'removeSpotlight') {
        mediaStream?.removeSpotlightedVideo(userId);
      } else if (key === 'addSpotlight') {
        mediaStream?.spotlightVideo(userId, false);
      } else if (key === 'replaceSpotlight') {
        mediaStream?.spotlightVideo(userId, true);
      } else if (key === 'unmute') {
        let changeName = '';
        try {
          let userName = host?.displayName ?? '';
          let object = JSON.parse(userName);
          if (object?.idsAudio && !object.idsAudio.includes(userId.toString())) {
            let infoHost = {
                ...object,
                idsAudio: [...object.idsAudio, userId.toString()]
              };
            changeName = JSON.stringify(infoHost) ?? '';
          }
        } catch (error) {
          let displayName = {
            idsPin: [],
            idsAudio: [userId.toString()],
            idsVideo: [],
            userName: host?.displayName,
            allowAllAudio: false, // true cho tất cả nói
            avatar: host?.avatar ?? null
          };
          changeName = JSON.stringify(displayName) ?? '';
        }

        if (user && user.isManager === false && user.isHost === false) {
          zmClient
            .changeName(changeName, host?.userId)
            .then(() => {
              message.success('Bật mic thành công');
            })
            .catch((error) => {
              message.error('Có lỗi xảy ra!');
            });
        } else {
          mediaStream
            ?.unmuteAudio(userId)
            .then(() => {
              message.success('Bật mic thành công');
            })
            .catch(() => {
              message.error('Bật mic thất bại');
            });
        }
      } else if (key === 'mute') {
        try {
          if (user && user.isManager === false && user.isHost === false) {
            let userName = host?.displayName ?? '';
            let object = JSON.parse(userName);
            let indexDelete = object.idsAudio.indexOf(userId.toString());
            if (object?.idsAudio && indexDelete > -1) {
              object.idsAudio.splice(indexDelete, 1);
              let changeName = JSON.stringify(object);
              let hostId = host?.userId ?? 0;
              zmClient
                .changeName(changeName, hostId)
                .then(() => {
                  message.success('Tắt mic thành công');
                })
                .catch((error) => {
                  message.success('Tắt mic thất bại ' + error);
                });
            }
          }
          mediaStream
            ?.muteAudio(userId)
            .then(() => {
              // message.success("Tắt mic thành công");
            })
            .catch(() => {
              message.error('Tắt mic thất bại');
            });
        } catch (error) {
          let displayName = {
            idsPin: [],
            idsAudio: [userId.toString()],
            idsVideo: [],
            userName: host?.displayName,
            allowAllAudio: false, // true cho tất cả nói
            avatar: host?.avatar ?? null
          };
          let changeName = JSON.stringify(displayName) ?? '';
          zmClient
            .changeName(changeName, host?.userId)
            .then(() => {
              message.success('Tắt mic thành công');
            })
            .catch((error) => {
              message.success('Tắt mic thất bại ' + error);
            });
        }
      }
      //   else if(key ==='muteAll'){
      //     mediaStream?.muteAllAudio();
      //   }
      else if (key === 'pin') {
        try {
          // if (user && user.isManager === false && user.isHost === false) {
          let userName = host?.displayName ?? '';
          let object = JSON.parse(userName);
          if (object?.idsPin && !object.idsPin.includes((i: number) => i === userId)) {
            // let infoHost = {
            //   idsPin: [...object.idsPin, userId.toString()],
            //   idsAudio: [...object.idsAudio],
            //   idsVideo: [...object.idsVideo],
            //   userName: object.userName,
            //   allowAllAudio: object.allowAllAudio, // true cho tất cả nói
            //   avatar: object.avatar ?? ''
            // };
            let infoHost = {
                ...object,
                idsPin: [...object.idsPin, userId.toString()]
              };
            let changeName = JSON.stringify(infoHost);
            zmClient
              .changeName(changeName, host?.userId)
              .then(() => {
                message.success('Ghim thành công');
              })
              .catch((error) => {
                message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
              });
          }
          // }
        } catch (error) {
          let displayName = {
            idsPin: [userId.toString()],
            idsAudio: [],
            idsVideo: [],
            userName: host?.displayName,
            allowAllAudio: false,
            avatar: host?.avatar ?? ''
          };
          let changeName = JSON.stringify(displayName);
          zmClient
            .changeName(changeName, host?.userId)
            .then(() => {
              message.success('Ghim thành công');
            })
            .catch((error) => {
              message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
            });
        }
      } else if (key === 'unpin') {
        try {
          // if (user && user.isManager === false && user.isHost === false) {
          let userName = host?.displayName ?? '';
          let object = JSON.parse(userName);
          let indexDelete = object.idsPin.indexOf(userId.toString());
          if (object?.idsPin && indexDelete > -1) {
            object.idsPin.splice(indexDelete, 1);
            let changeName = JSON.stringify(object);
            zmClient
              .changeName(changeName, host?.userId)
              .then(() => {
                message.success('Bỏ ghim thành công');
              })
              .catch((error) => {
                message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
              });
          }
          // }
        } catch (error) {
          let displayName = {
            idsPin: [],
            idsAudio: [],
            idsVideo: [],
            userName: host?.displayName,
            allowAllAudio: false,
            avatar: host?.avatar ?? ''
          };
          let changeName = JSON.stringify(displayName);
          zmClient
            .changeName(changeName, host?.userId)
            .then(() => {
              message.success('Bỏ ghim thành công');
            })
            .catch((error) => {
              message.success('Có lỗi xảy ra ' + error + ' .Vui lòng thử lại');
            });
        }
      }else if(key === 'kick'){
        zmClient.removeUser(userId)
        .then(() => {
            message.success("Kick user thành công");
        })
        .catch((error) => {
            console.log('error: ', error);
            
            message.success("Có lỗi xảy ra. Vui lòng thử lại");
        });
      }

      setIsDropdownVisbile(false);
    },
    [mediaStream, userId, isControllingRemoteCamera, dispatch]
  );
  return (
    <>
      {menu.length > 0 && (
        <Dropdown
          menu={getAntdDropdownMenu(menu, onMenuItemClick)}
          placement="bottomRight"
          trigger={['click']}
          onOpenChange={onDropDownVisibleChange}
        >
          <Button
            icon={<MoreOutlined />}
            className={classNames('more-button', {
              'more-button-active': isHover || isDropdownVisible
            })}
            type="primary"
            size="small"
          />
        </Dropdown>
      )}
      {isHover && actionItem?.localVolumeAdjust?.enabled && actionItem?.localVolumeAdjust?.toggled && (
        <div className={classNames('avatar-volume')}>
          <label>Volume:</label>
          <Slider
            marks={{ 0: '0', 100: '100' }}
            defaultValue={100}
            onChange={onSliderChange}
            value={actionItem.localVolumeAdjust.volume}
          />
        </div>
      )}
    </>
  );
};

export default AvatarMore;
