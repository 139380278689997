import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'Fr9lC1U8n0bkwQ3mmMtS9iGkQRdH6nBnb1i1',
  sdkSecret: 'bX3VUEraUg1BNC3GyQQBwj2r1HOtiAv6G39R',
  webEndpoint: 'zoom.us', // zfg use www.zoomgov.com
  topic: 'bbc17ba0-f5cb-49be-a215-4e3c6b16f6ec',
  //   topic: 'dev',
  name: `${getExploreName()} - Kai - ${Math.floor(Math.random() * 1000)}`,
  password: '',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};
